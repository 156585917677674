import { Button, Modal } from '@anchor/react-components/dist/lib/components';
import NotificationComponent from '../../../Components/Notification/Notification';

interface ErrorDetailsProps {
  showErrorModal: boolean;
  setShowErrorModal: (show: boolean) => void;
  errorDetails: string[];
  isCharter?: boolean
}

const DtcoErrorDetailsModal = ({
  showErrorModal,
  setShowErrorModal,
  errorDetails,
  isCharter
}: ErrorDetailsProps) => (
  <Modal
    actions={{
      primaryAction: (
        <Button
          label='Dismiss'
          variant='outlined'
          fit='small'
          onClick={() => setShowErrorModal(false)}
        />
      ),
    }}
    backdropcloseactiondisabled
    onClose={() => setShowErrorModal(false)}
    heading={ isCharter ? 'Error Details in TCO Run': 'Error Details in DTCO Run'}
    open={showErrorModal}
  >
    <>
      {errorDetails?.map((error, index) => (
          <NotificationComponent
            message={error}
            type='error'
            key={index}
          />
      ))}
    </>
  </Modal>
);
export default DtcoErrorDetailsModal;