//React
import React, { useEffect, useState } from "react";
//Anchor UI
import { Button, Modal, toastEmitter } from "@anchor/react-components/dist/lib/components";
//Services
import { postAPI } from "../../Services/APIservices";
//Models
import { VesselDetailsModel } from "./DictionaryModel";
interface EditVesselDetailsProps {
    rowData: VesselDetailsModel;
    open: boolean;
    reloadAPI?: any;
    onClose: () => void;
}
export const EditVesselDetails = ({ rowData, open, reloadAPI, onClose }: EditVesselDetailsProps) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [error, setError] = useState({ startDate: "", endDate: "" });

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
        if (event.target.value !== "") {
            setError(prev => ({ ...prev, startDate: "" }));
        }
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
        if(event.target.value !== ""){
            setError(prev => ({...prev, endDate: ""}));
        }
    };

    useEffect(() => {
        setStartDate(rowData?.from.split("T")[0]);
        setEndDate(rowData?.to.split("T")[0]);
        setError({ startDate: "", endDate: "" });
    }, [rowData, open]);

    const handleSubmit = async () => {
        let newError = { startDate: "", endDate: "" };
        let formValid = true;
        if (startDate === "") {
            newError.startDate = "Please select start date";
            formValid = false;
        }
        if (endDate === "") {
            newError.endDate = "Please select end date";
            formValid = false;
        }
        if(startDate !== "" && endDate !== "" && startDate > endDate){
            newError.startDate = "Start date should be less than end date";
            formValid = false;
        }
        if (formValid) {
            //API call to save the data
            const payload = {
                imoNumber: null,
                from: startDate,
                to: endDate,
                id: rowData.id,
                isActive: false,
                vesselName: null
            }
            const response = await postAPI(`dtco-api/dictionary/UpsertSpecialVesselDetails`, payload);
            if (response) {
                reloadAPI();
                onClose();
                toastEmitter(
                    { className: "customClassName", title: "Vessel details updated successfully." },
                    { type: "success" } 
                );
            }
        } else {
            setError(newError);
        }
    };

    return (
        <Modal
            actions={{
                primaryAction: (
                    <Button label="Save" onClick={handleSubmit} fit="small" />
                ),
                secondaryAction: (
                    <Button
                        label="Dismiss"
                        fit="small"
                        variant="outlined"
                        data-testid="close"
                        onClick={onClose}
                    />
                ),
            }}
            backdropcloseactiondisabled
            heading="Edit Vessel Details"
            height="auto"
            open={open}
            showCloseIcon={true}
            onClose={onClose}
            width="800px"
        >
            <div className="p-2">
                <div className="row">
                    <div className="col-md-12 mb-3 d-flex">
                        <span>Vessel Name: </span>
                        <span className="mx-2">{rowData?.vesselName}</span>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="input-section">
                            <label>From <span className="mandatory">*</span></label>
                            <input
                                type="date"
                                max={endDate}
                                className="form-control"
                                value={startDate}
                                onChange={handleStartDateChange}
                                data-testid="from-date"
                                required
                            />
                            {error.startDate && <p className="error-message">{error.startDate}</p>}
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="input-section">
                            <label>To <span className="mandatory">*</span></label>
                            <input
                                type="date"
                                className="form-control"
                                min={startDate}
                                value={endDate}
                                onChange={handleEndDateChange}
                                data-testid="to-date"
                                required
                            />
                            {error.endDate && <p className="error-message">{error.endDate}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
};
export default EditVesselDetails;