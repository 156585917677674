import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Anchor Components
import { TableV2 } from "@anchor/react-components/dist/lib/components";
import {
  Button,
  Input,
  Drawer,
  toastEmitter,
  Checkbox,
} from "@anchor/react-components/dist/lib/components";

// styles
import "./SimulationRequestResult.scss";

// Components
import SimulationDataFeedback from "../SimulationResultFeedback/SimulationDataFeedback";
import SimulationDataAddFeedbackComponent from "../SimulationDataAddFeedback/SimulationDataAddFeedback";
import SimulationBanner from "../SimulationBanner/SimulationBanner";
import PageLoader from "../../../../Components/PageLoader/PageLoader";
import SimulationRequestHistoryTable from "../SimulationRequestHistoryTable/SimulationRequestHistoryTable";

// Services
import { getAPI, postAPI } from "../../../../Services/APIservices";

// Model and Constants
import { SimulationData, SolutionData } from "../../Model";
import { excelDownload } from "../../../../utils/excelDownload";
import { AppString } from "../../../../utils/AppString";
import { appRoles } from "../../../../utils/Constants";

export const SimulationRequestResult = () => {
  const userRole = sessionStorage.getItem("userRole");
  const { requestId } = useParams<{ requestId: string }>();
  const [isOpen, setIsOpen] = useState(false);
  const [openResultLogModal, setOpenResultLogModal] = useState(false);
  const [simulationData, setSimulationData] = useState<SimulationData>();
  const [filterData, setFilterData] = useState<SimulationData>() as any;
  const [checkedState, setCheckedState] = useState({});
  const [isAnyChecked, setIsAnyChecked] = useState(false);
  const [selectedServiceRank, setSelectedServiceRank] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(60);
  const [serviceCodes, setServiceCodes] = useState<string[]>([]);

  const getTemplate = (item) => {
    return (
      <div
        className={`${getBackgroundClass(
          item.toFixed(2)
        )} fw-semibold total-delta`}
      >
        {item.toFixed(2)}
      </div>
    );
  };

  const getSimulationData = async () => {
    const url = `simulation-api/Simulation/GetRecomendationsAsync/${requestId}`;
    await getAPI(url)
      .then(async (response) => {
        const res = response?.solutionData?.map((item) => {
          if (item.vesselListIn === null) {
            item.vesselListIn = "-";
          }
          if (item.serviceIn === null) {
            item.serviceIn = "-";
          }
          if (item.vesselListOut === null) {
            item.vesselListOut = "-";
          }
          if (item.serviceOut === null) {
            item.serviceOut = "-";
          }
          return item;
        });
        // rearrange the res based on solutionRank and serviceRankOrder in ascending order solutionRank 1 with serviceRankOrder 0 will be on top
        res.sort((a, b) => {
          return (
            a.solutionRank - b.solutionRank ||
            a.serviceRankOrder - b.serviceRankOrder
          );
        });

        const formattedResponse = {
          ...response,
          solutionData: res,
        };

        const rankMap: { [key: number]: any[] } = {};
        formattedResponse.solutionData.forEach((row) => {
          if (!rankMap[row.solutionRank]) {
            rankMap[row.solutionRank] = [];
          }
          rankMap[row.solutionRank].push(row);
        });

        // loop through rankMap and get the max serviceRankOrder for each solutionRank and get the heighest value and assign it to maxServiceRankOrder
        const maxServiceRankOrder = Object.keys(rankMap).reduce((acc, rank) => {
          const maxServiceRankOrder = rankMap[rank].reduce(
            (acc, row) => Math.max(acc, row.serviceRankOrder),
            0
          );
          return Math.max(acc, maxServiceRankOrder);
        }, 0);

        const itemPerPage = (maxServiceRankOrder) => {
          return maxServiceRankOrder*5 + 10;
        };

        setItemsPerPage(itemPerPage(maxServiceRankOrder));


        const result: any[] = [];
        Object.keys(rankMap).forEach((rank) => {
          const rows = rankMap[rank];
          const missingRows = maxServiceRankOrder - rows.length;
          if (missingRows >= 0) {
            rankMap[rank].push({
              solutionRank: parseInt(rank, 10),
              serviceCode: "-",
              serviceRankOrder: rows.length + 1,
              vesselInName: "-",
              vesselOutName: "-",
              deltaFuel: 0,
              deltaCyMin: 0,
              deltaCyAvg: 0,
              deltaCyMax: 0,
              deltaEbitAvg: 0,
              deltaEbitMax: 0,
              deltaEbitMin: 0,
            });
          }
        });

        // Iterate over the map and add summed deltaFuel rows
        Object.keys(rankMap).forEach((rank) => {
          const rows = rankMap[rank];
          const searchId = rows[0].solutionRank;
          const summedRow = rows.reduce(
            (acc, row) => ({
              serviceRankOrder: "",
              serviceCode: "",
              vesselInName: "",
              vesselOutName: "",
              deltaFuel: acc.deltaFuel + row.deltaFuel,
              deltaCyMin: acc.deltaCyMin + row.deltaCyMin,
              deltaCyAvg: acc.deltaCyAvg + row.deltaCyAvg,
              deltaCyMax: acc.deltaCyMax + row.deltaCyMax,
              deltaEbitAvg: acc.deltaEbitAvg + row.deltaEbitAvg,
              deltaEbitMax: acc.deltaEbitMax + row.deltaEbitMax,
              deltaEbitMin: acc.deltaEbitMin + row.deltaEbitMin,
            }),
            {
              solutionRank: parseInt(rank, 10),
              deltaFuel: 0,
              deltaCyMin: 0,
              deltaCyAvg: 0,
              deltaCyMax: 0,
              deltaEbitAvg: 0,
              deltaEbitMax: 0,
              deltaEbitMin: 0,
            }
          );
          // Add original rows
          result.push(...rows);
          summedRow.searchId = searchId;
          result.push(summedRow);
        });
        // loop through result and assign value to deltaFuel, deltaCyMin, deltaCyAvg, deltaCyMax, deltaEbitAvg, deltaEbitMax, deltaEbitMin to 0 when vesselInName is null
        result.forEach((item) => {
          if (item.vesselInName === "") {
            item.deltaFuel = getTemplate(item.deltaFuel);
            item.deltaCyMin = getTemplate(item.deltaCyMin);
            item.deltaCyAvg = getTemplate(item.deltaCyAvg);
            item.deltaCyMax = getTemplate(item.deltaCyMax);
            item.deltaEbitAvg = getTemplate(item.deltaEbitAvg);
            item.deltaEbitMax = getTemplate(item.deltaEbitMax);
            item.deltaEbitMin = getTemplate(item.deltaEbitMin);
          }
        });
        // loop through solutionData and if solutionRank is same for more than one item then add property new key config to the object with value as 2 to the first item and remove the solutionRank from other items

        result.forEach((item, index) => {
          if (!result[index].searchId) {
            result[index].searchId = item.solutionRank;
          }
          if (item.serviceRankOrder === 0) {
            result[index].isSelected = false;
            result[index].config = [
              {
                key: "solutionRank",
                rowSpan: maxServiceRankOrder + 2,
              },
              {
                key: "isSelected",
                rowSpan: maxServiceRankOrder + 2,
              },
            ];
          } else {
            delete item.solutionRank;
            delete item.isSelected;
          }
        });
        const finalFormattedResponse = {
          ...response,
          solutionData: result,
        };
        setSimulationData(finalFormattedResponse);
        setFilterData(finalFormattedResponse);
      })
      .catch((error) => {
        toastEmitter(
          {
            className: "customClassName",
            title: "Error occured while fetching simulation results.",
          },
          { type: "error" }
        );
        console.error("An error occurred:", error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      await getSimulationData();
      localStorage.removeItem(`simulationCommentsHistory`);
    };
    fetchData();
  }, []);

  const getBackgroundClass = (value) => {
    if (value === 0) return "zero";
    return value < 0 ? "negative" : "positive";
  };

  const handleCheckboxChange = (solutionRank: number) => (event) => {
    setSelectedServiceRank([]);
    setServiceCodes([]);
    setSelectedServiceRank([solutionRank]);
    simulationData?.solutionData.forEach((item) => {
      if (item.searchId === solutionRank && item.serviceCode !== "") {
        setServiceCodes((prev) => [...prev, item.serviceCode]);
      }
    });
    const isChecked = event.target.checked;
    setCheckedState({
      ...checkedState,
      [event.target.id]: event.target.checked,
    });
    if (isChecked) {
      setIsAnyChecked(true);
    } else {
      const isStillAnyChecked = Object.values(checkedState).some(
        (value) => value
      );
      setIsAnyChecked(!isStillAnyChecked);
    }
  };

  const hadleCreateNewDTCO = async (selectedSolution) => {
    if (selectedServiceRank[0] !== 1 && selectedSolution === "topSolution") {
      setOpenResultLogModal(true);
      return;
    }

    setIsLoading(true);
    const url = `/dtco-api/dtcosummary/CreateRequestIdAndDtcoProposalAsync`;
    const payload = {
      simulationRequestId: requestId,
      serviceRanks: selectedServiceRank,
      vesselName: simulationData?.simulationRequestResponse?.vesselName,
      serviceCodes: serviceCodes,
    };
    postAPI(url, payload)
      .then((response) => {
        toastEmitter(
          {
            className: "customClassName",
            title: "DTCO proposal created successfully",
          },
          { type: "success" }
        );
        setIsLoading(false);
        window.location.href = `/RequestPage/DtcoOverviewPage/${response.requestId}`;
      })
      .catch((error) => {
        setIsLoading(false);
        toastEmitter(
          {
            className: "customClassName",
            title: "Error occured while creating DTCO proposal.",
          },
          { type: "error" }
        );
        console.error("An error occurred:", error);
      });
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const searchForVessel = (e) => {
    const searchValue = e.target.value;
    if (!searchValue) {
      setFilterData(simulationData);
      setCurrentPage(1);
      return;
    }
    const searchId = simulationData?.solutionData.filter(
      (item) =>
        item.vesselInName?.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.vesselOutName?.toLowerCase().includes(searchValue.toLowerCase())
    );
    const uniqueSearchId = Array.from(
      new Set(searchId?.map((a) => a.searchId))
    );
    let filteredData: SolutionData[] = [];
    uniqueSearchId?.forEach((id) => {
      const data = simulationData?.solutionData.filter(
        (item) => item.searchId === id
      );
      filteredData = [...filteredData, ...(data || [])];
    });

    setFilterData({
      ...simulationData,
      solutionData: filteredData,
    });
    setCurrentPage(1);
  };

  const paginatedData = filterData?.solutionData?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const columns = [
    {
      id: "isSelected",
      accessorKey: "isSelected",
      header: "",
      alignData: "center",
      enableSorting: false,
      cell: ({ row }) => (
        <div className="d-flex justify-content-center">
          <Checkbox
            id={"solution" + row.original.solutionRank}
            name={"solution" + row.original.solutionRank}
            fit="medium"
            label=""
            checked={!!checkedState["solution" + row.original.solutionRank]}
            disabled={
              isAnyChecked &&
              !checkedState["solution" + row.original.solutionRank]
            }
            onChange={handleCheckboxChange(row.original.solutionRank)}
          />
        </div>
      ),
    },
    {
      accessorKey: "solutionRank",
      header: "Rank",
      id: "solutionRank",
      enableSorting: false,
      alignData: "center",
      meta: {
        type: "custom",
      },
    },
    {
      accessorKey: "serviceCode",
      header: "Service",
      id: "serviceIn",
      serviceCode: false,
    },
    {
      accessorKey: "vesselInName",
      header: "Vessel(s) In",
      id: "vesselInName",
      enableSorting: false,
    },
    {
      accessorKey: "vesselOutName",
      header: "Vessel(s) Out",
      id: "vesselOutName",
      enableSorting: false,
    },
    {
      accessorKey: "deltaCY",
      columns: [
        {
          accessorKey: "deltaCyMin",
          header: "Min.",
          id: "deltaCyMin",
          alignData: "center",
          enableSorting: false,
          meta: {
            type: "custom",
          },
        },
        {
          accessorKey: "deltaCyAvg",
          header: "Avg.",
          id: "deltaCyAvg",
          alignData: "center",
          enableSorting: false,
          meta: {
            type: "custom",
          },
        },
        {
          accessorKey: "deltaCyMax",
          header: "Max.",
          id: "deltaCyMax",
          alignData: "center",
          enableSorting: false,
          meta: {
            type: "custom",
          },
        },
      ],
      enableSorting: false,
      header: "Delta CY",
      id: "deltaCY",
    },
    {
      accessorKey: "deltaFuel",
      header: "Delta Fuel",
      id: "deltaFuel",
      alignData: "center",
      enableSorting: false,
      maxWidth: "100px",
      meta: {
        type: "custom",
      },
    },
    {
      accessorKey: "deltaEBIT",
      columns: [
        {
          accessorKey: "deltaEbitMin",
          header: "Min.",
          id: "deltaEbitMin",
          alignData: "center",
          enableSorting: false,
          meta: {
            type: "custom",
          },
        },
        {
          accessorKey: "deltaEbitAvg",
          header: "Avg.",
          id: "deltaEbitAvg",
          alignData: "center",
          enableSorting: false,
          meta: {
            type: "custom",
          },
        },
        {
          accessorKey: "deltaEbitMax",
          header: "Max.",
          id: "deltaEbitMax",
          alignData: "center",
          enableSorting: false,
          meta: {
            type: "custom",
          },
        },
      ],
      enableSorting: false,
      header: "Delta EBIT",
      id: "deltaEBIT",
    },
  ];

  const downloadExcel = () => {
    const dataSource = simulationData?.solutionData?.map((e: any) => {
      return {
        solutionRank: e.solutionRank,
        serviceCode: e.serviceCode,
        vesselInName: e.vesselInName,
        vesselOutName: e.vesselOutName,
        deltaCyMin: isNaN(e.deltaCyMin)
          ? e.deltaCyMin.props?.children
          : e.deltaCyMin,
        deltaCyAvg: isNaN(e.deltaCyAvg)
          ? e.deltaCyAvg.props?.children
          : e.deltaCyAvg,
        deltaCyMax: isNaN(e.deltaCyMax)
          ? e.deltaCyMax.props?.children
          : e.deltaCyMax,
        deltaFuel: isNaN(e.deltaFuel)
          ? e.deltaFuel.props?.children
          : e.deltaFuel,
        deltaEbitMin: isNaN(e.deltaEbitMin)
          ? e.deltaEbitMin.props?.children
          : e.deltaEbitMin,
        deltaEbitAvg: isNaN(e.deltaEbitAvg)
          ? e.deltaEbitAvg.props?.children
          : e.deltaEbitAvg,
        deltaEbitMax: isNaN(e.deltaEbitMax)
          ? e.deltaEbitMax.props?.children
          : e.deltaEbitMax,
      };
    });

    const header = [
      "Solution Rank",
      "Service Code",
      "Vessel List In",
      "Vessel List Out",
      "Delta CY Min",
      "Delta CY Avg",
      "Delta CY Max",
      "Delta Fuel",
      "Delta EBIT Min",
      "Delta EBIT Avg",
      "Delta EBIT Max",
    ];

    excelDownload("Request_Data", dataSource, header);
  };

  if (!simulationData) {
    return <PageLoader isLoader={true} />;
  }
  return (
    <div
      className={`simulation-section p-3 ${
        isOpen ? "reduced-width" : "complete-width"
      }`}
    >
      <div className="align-items-center d-flex justify-content-between">
        <h1 className="header-text">Simulation Result</h1>
        {userRole === appRoles.DeploymentUser && (
          <div className="d-flex gap-2">
            <Button
              id="primary"
              justifyItems="center"
              label="Create new DTCO proposal"
              name="primary"
              fit="small"
              title="Proceed"
              variant="filled"
              onClick={() => hadleCreateNewDTCO("topSolution")}
              disabled={!isAnyChecked}
              loading={isLoading}
            />
          </div>
        )}
      </div>
      <SimulationBanner runDetails={simulationData.simulationRequestResponse} />
      {simulationData?.solutionData?.length ? (
        <>
          <div className="mb-2">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <Input
                  clearButton
                  fit="small"
                  icon="magnifying-glass"
                  iconPosition="left"
                  id="searchInput"
                  placeholder="Search for Vessel Name"
                  variant="default"
                  width={100}
                  onChange={searchForVessel}
                />
              </div>
              <div className="d-flex">
                <Button
                  className="mx-2"
                  variant="outlined"
                  label="Simulation Result Log"
                  onClick={() => setIsOpen(true)}
                  fit="small"
                />
                <Button
                  appearance="default"
                  id="export-excel"
                  icon="tray-arrow-down"
                  justifyItems="center"
                  label="Export"
                  name="Excel"
                  fit="small"
                  onClick={downloadExcel}
                  iconPosition="right"
                  variant="outlined"
                />
              </div>
            </div>
          </div>
          <div className="simulation-result-table">
            <TableV2
              columnData={columns}
              defaultData={paginatedData || []}
              gridLine="both"
              fit="small"
              currentPage={currentPage}
              onPageChange={handlePageChange}
              defaultSelectedValue={itemsPerPage}
              totalPages={Math.ceil(
                simulationData?.solutionData.length / itemsPerPage
              )}
              hidePageSizeOptions={true}
              showPagination
            />
          </div>

          <div className="mt-4">
            <h5>{AppString.simulationProposalCreatedTitle}</h5>
            <SimulationRequestHistoryTable
              simulationRequestHistory={
                simulationData?.simulationDtcoRequestHistory
              }
            />
          </div>
          <div>
            <div className="w-50">
              <Drawer
                className="drawer"
                direction="right"
                onClose={() => setIsOpen(false)}
                open={isOpen}
                zindex={9999}
              >
                <SimulationDataFeedback
                  requestId={requestId}
                  isDrawerOpen={isOpen}
                />
              </Drawer>
            </div>
          </div>
          <div>
            <SimulationDataAddFeedbackComponent
              showCommentsModal={openResultLogModal}
              setShowCommentsModal={setOpenResultLogModal}
              selectedServiceRank={selectedServiceRank}
              requestId={requestId}
              onCreateNewDTCO={() => hadleCreateNewDTCO("otherSolution")}
            />
          </div>
        </>
      ) : (
        <div className="no-data">
          <h4>No data available</h4>
        </div>
      )}
    </div>
  );
};
