// React
import React, { useEffect, useState } from 'react';
//Anchor UI
import { Button, Icon, Notification, ReactSelect, TableV2, toastEmitter } from '@anchor/react-components/dist/lib/components';
// Services
import { deleteAPI, getAPI, postAPI } from '../../Services/APIservices';
// Components
import EditVesselDetails from './EditVesselDetails';
import ConfirmationModalPopUp from '../FBPValidation/AddOnComponent/ConfirmationModalPopUp';
import { SkeletonLoader } from '../../Components/SkeletonLoader/SkeletonLoader';
// Models and Constants
import { VesselDetailsModel, VesselListModel } from './DictionaryModel';
import { AppString } from '../../utils/AppString';
// Styles
import './Dictionary.scss';
import { appRoles } from '../../utils/Constants';
import { fetchDateFormat } from '../../utils/CustomDateTime';

const SpecialVesselDictionary = () => {
  const [selectedVessel, setSelectedVessel] = useState<VesselListModel | undefined>();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [vesselList, setVesselList] = useState<VesselListModel[]>([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editRow, setEditRow] = useState<VesselDetailsModel>();
  const [tableData, setTableData] = useState<VesselDetailsModel[]>([]);
  const [storeTableData, setStoreTableData] = useState<VesselDetailsModel[]>([]);
  const [isSelectLoader, setIsSelectLoader] = useState(false);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [errorMessageForAddVessel, setErrorMessageForAddVessel] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const userRole = sessionStorage.getItem("userRole");
  const checkRoleForAction = userRole === appRoles.DeploymentUser;
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  //API call to get the vessel list data for dropdown
  const getVesselList = async () => {
    const response = await getAPI(`dtco-api/dtcosummary/GetVesselDetails`);
    if (response) {
      const res = response?.map((item) => {
        return {
          label: item.label + " (" + item.value + ")",
          value: item.value,
        };
      });
      setVesselList(res);
    }
  };

  //API call to get the special vessel details data for the table
  const getSpecialVesselDetails = async () => {
    setIsSelectLoader(true);
    const response = await getAPI(`dtco-api/dictionary/GetSpecialVessels`);
    if (response) {
      const data = response.map((item) => {
        return {
          id: item.id,
          vesselName: item.vesselName,
          from: item.from ? item.from : "",
          to: item.to ? item.to : "",
          isActive: item.isActive,
          action: "",
          imoNumber: item.imoNumber
        };
      });
      setTableData(data);
      setStoreTableData(data);
      setIsSelectLoader(false);
    }
  }
  //Initial API call to get the data
  useEffect(() => {
    getVesselList();
    getSpecialVesselDetails();
  }, []);

  //Function to handle date range value in the table
  const handletDateRange = (value) => {
    if (value === null || value === undefined || value === "") {
      return "-";
    } else {
      return fetchDateFormat(value);
    }
  }
  //Function to handle active value in the table
  const handleActiveChange = (value) => {
    if (value) {
      return <span style={{ color: "var(--color-green)" }}><Icon name='check-circle' color="#40AB35" /></span>;
    } else {
      return <span style={{ color: "var(--color-red)" }}><Icon name='times-circle' /></span>;
    }
  }
  //Function to handle action button in the table
  const actionButtonHandler = (row) => {
    return (
      <div className="d-flex flex-row  w-100">
        <Button
          appearance="default"
          id="primary"
          icon="pencil"
          justifyItems="center"
          label={''}
          name="Edit"
          fit="small"
          iconPosition="right"
          title="Edit"
          onClick={() => {
            setOpenEditModal(true);
            setEditRow(row.original);
          }}
          variant="outlined"
        />
        <Button
          className="mx-2"
          appearance="default"
          id="copy-request"
          icon="trash"
          title="Delete"
          justifyItems="center"
          label={''}
          name="Edit"
          fit="small"
          iconPosition="right"
          onClick={() => {
            setIsDeletePopupOpen(true);
            setEditRow(row.original);
          }}
          variant="outlined"
        />
      </div>
    );
  };

  //Table Column Data
  const specialVesselTableColumn = [
    {
      id: "vessel",
      accessorKey: "vesselName",
      header: "Vessel Name",
      size: 150,
      enableSorting: false,
    },
    {
      id: "fromDate",
      accessorKey: "from",
      header: "From",
      enableSorting: false,
      size: 140,
      cell: ({ getValue }) => handletDateRange(getValue()),
    },
    {
      id: "toDate",
      accessorKey: "to",
      header: "To",
      enableSorting: false,
      size: 140,
      cell: ({ getValue }) => handletDateRange(getValue()),
    },
    {
      id: "active-vessel",
      accessorKey: "isActive",
      header: "Active",
      enableSorting: false,
      size: 70,
      cell: ({ getValue }) => handleActiveChange(getValue()),
    },
    {
      id: "action",
      accessorKey: "action",
      header: "Action",
      enableSorting: false,
      size: 70,
      cell: ({ row }) => checkRoleForAction ? actionButtonHandler(row) : "",
    },

  ];
  //Function to reload the API
  const handleReloadAPI = () => {
    getSpecialVesselDetails();
  };

  //Function to delete the vessel data
  const handleDeleteFunc = async () => {
    deleteAPI(
      `/dtco-api/Dictionary/DeleteSpecialVessel/${editRow?.id}`
    ).then((response) => {
      if (response === true) {
        toastEmitter(
          { className: "customClassName", title: "Deleted sucessfully" },
          { type: "success" }
        );
        handleReloadAPI();
      } else {
        toastEmitter(
          { className: "customClassName", title: "Something went wrong" },
          { type: "error" }
        );
      }
    });
  };

  //Function to add the vessel data and save it
  const handleSaveVesselDetails = async () => {
    if (selectedVessel !== undefined && selectedVessel !== null && selectedVessel?.label !== "" ) {
      setAddLoading(true);
      const payload = {
        id: 0,
        imoNumber: selectedVessel?.value?.toString(),
        from: null,
        to: null,
        isActive: false,
        vesselName: null,
      };
      const response = await postAPI(`dtco-api/dictionary/UpsertSpecialVesselDetails`, payload);
      if (response > 0) {
        toastEmitter(
          { className: "customClassName", title: "Vessel added successfully" },
          { type: "success" }
        );
        getSpecialVesselDetails();
      } else if(response.includes("Record already exists with")){ 
        toastEmitter(
          { className: "customClassName", title: " Record already exists" },
          { type: "error" }
        )
      }
      else{
        toastEmitter(
          { className: "customClassName", title: "Something went wrong" },
          { type: "error" }
        );
      }
      setSelectedVessel(undefined);
      setAddLoading(false);
    } else {
      setErrorMessageForAddVessel(true);
    }
  }

  //Filter data based on time period
  const filterData = () => {
    if (startDate !== '' && endDate !== '') {
      const filtered = storeTableData?.filter((item) => {
        return (
          new Date(item.from) >= new Date(startDate + "T00:00:00") &&
          new Date(item.to) <= new Date(endDate + "T00:00:00")
        );
      });
      setTableData(filtered);
    }else{
      setTableData(storeTableData);
    }
  }
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const handlePageSizeChange = (size: number) => {
    setItemsPerPage(size);
    setCurrentPage(1);
  };
  const paginatedData = tableData?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  //Function to handle the date range filter
  useEffect(() => {
    filterData();
  }, [startDate, endDate]);

  return (
    <div className='dictionary-page mt-3 p-4'>
      <h1 className='header-text'>Special Vessels </h1>
      <div className='row'>
        <div className='col-md-6 details-section'>
          <div className='align-self-sm-start details-box p-3 text-start'>
            <div className='d-flex flex-row gap-2'>
              <ReactSelect
                className='react-select flex-grow-1'
                closeMenuOnScroll
                fit='medium'
                id='vesselName'
                isClearable
                isSearchable
                label='Add Vessel'
                name='vesselName'
                onChange={(e) => {
                  setSelectedVessel(e);
                  setIsAddButtonDisabled(!isAddButtonDisabled);
                  setErrorMessageForAddVessel(false);
                }}
                value={selectedVessel ? [selectedVessel] : []}
                options={vesselList}
                orientation='vertical'
                placeholder='Search by Vessel'
                renderValue='label'
                data-testid="vesselName"
                disabled={!checkRoleForAction}
                required = {true}
                error = {errorMessageForAddVessel}
                errorMessage='Please select a vessel'
              />
              <div className='add-button-dictionary'>
              <Button label="Add"
                appearance="default"
                id="add-button"
                justifyItems="center"
                name="add-button"
                title="Add"
                variant="primary"
                onClick={handleSaveVesselDetails}
                data-testid="add-vessel-button"
                disabled={isAddButtonDisabled && !checkRoleForAction}
                loading={addLoading}
              />
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-6 details-section'>
          <div className='align-self-sm-end details-box p-3 text-start'>
            <h6>Filters Time Period</h6>
            <div className="d-flex flex-row h-50 mt-1 gap-2">
              <input
                type="date"
                max={endDate}
                className="form-control"
                value={startDate}
                onChange={handleStartDateChange}
              />
              <span className="align-self-md-center">to</span>
              <input
                type="date"
                className="form-control"
                min={startDate}
                value={endDate}
                onChange={handleEndDateChange}
              />
              <Button
                appearance="default"
                id="refresh-time-period"
                icon="arrow-clockwise"
                justifyItems="center"
                label=""
                name="refresh-time-period"
                fit="small"
                onClick={() => {
                  setStartDate("");
                  setEndDate("");
                  setTableData(storeTableData);
                }}
                title="Refresh"
                variant="outlined"
              />
            </div>
          </div>
        </div>
      </div>

      <div className='mt-4'>
        <div>
          {isSelectLoader ? (
            <SkeletonLoader isLoader={isSelectLoader}></SkeletonLoader>
          ) : (
            <TableV2
              columnData={specialVesselTableColumn}
              defaultData={paginatedData}
              stickyHeader={true}
              gridLine='both'
              totalPages={Math.ceil(
                tableData?.length / itemsPerPage
              )}
              showPagination={tableData?.length > 0}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              defaultSelectedValue={itemsPerPage}
              onPageSizeOptionsChange={handlePageSizeChange}
              pageSizeOptions={[10, 20, 30, 50]}
              customTableBody={tableData?.length === 0 && <div style={{padding: '64px'}}><Notification heading="No data found" icon="info-circle" variant="warning"/></div>}
            />          
          )}
          {
            editRow && openEditModal && (
              <EditVesselDetails
                rowData={editRow}
                open={openEditModal}
                reloadAPI={handleReloadAPI}
                onClose={() => setOpenEditModal(false)}
              />
            )
          }
        </div>
      </div>
      <ConfirmationModalPopUp
        modalHeader={AppString.specialVesselDeleteHeader}
        isOpen={isDeletePopupOpen}
        modalBody={AppString.specialVesselDeleteComment}
        modalButton={{ primary: "Yes", secondary: "No" }}
        onClose={() => setIsDeletePopupOpen(false)}
        submitFun={handleDeleteFunc}
      />
    </div>
  );
}
export default SpecialVesselDictionary;