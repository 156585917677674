import { DtcoTableColumn } from "../../DtcoOverviewPage/DtcoModel";

export const tableColumns: DtcoTableColumn[] = [
  {
    id: "selectionCheckbox",
    header: "",
    accessorKey: "selectionCheckbox",
    alignData: "left",
    editable: false,
    type: "selectionCheckbox",
    convertToLocaleString: false,
    rowid: 0
  },
  {
    id: "serviceName",
    header: "Service Name",
    accessorKey: "serviceName",
    alignData: "left",
    editable: false,
    type: "text",
    convertToLocaleString: false,
    rowid: 0
  },
  {
    id: "vesselName",
    header: "Vessel Name",
    accessorKey: "vesselName",
    alignData: "left",
    editable: true,
    type: "text",
    convertToLocaleString: false,
    rowid: 1
  },
  {
    accessorKey: "nopat",
    header: "NOPAT ($/Yr)",
    id: "nopat",
    convertToLocaleString: true,
    type: "number",
    alignData: "right",
    editable: false,
    dollarSign: true,
    rowid: 47
  },
  {
    // accessorKey: "segment"
    header: "Segment Size",
    id: "segment",
    type: "text",
    accessorKey: "",
    alignData: "",
    editable: false,
    convertToLocaleString: false
  },
  {
  header: "DESIGN CLASS",
  id: "designClass",
  accessorKey: "designClass",
  alignData: "left",
  editable: false,
  type: "text",
  convertToLocaleString: false,
  },
  {
    id: "ciiRating",
    header: "CII RATING",
    accessorKey: "ciiRating",
    alignData: "left",
    editable: false,
    type: "text",
    convertToLocaleString: false,
  },
  {
    id: "planningGroup",
    header: "PLANNING GROUP",
    accessorKey: "planningGroup",
    alignData: "left",
    editable: false,
    type: "text",
    convertToLocaleString: false,
  },
  {
    header: "EEOI",
    id: "eeoi",
    accessorKey: "eeoi",
    alignData: "left",
    editable: false,
    type: "text",
    convertToLocaleString: false,

  },
  {
    id: "roundTripDistance",
    header: "R/T(nm)",
    accessorKey: "roundTripDistance",
    alignData: "right",
    editable: false,
    type: "number",
    convertToLocaleString: false,
    rowid: 0
  },
  {
    id: "roundTripDays",
    header: "R/T(days)",
    accessorKey: "roundTripDays",
    alignData: "right",
    editable: false,
    type: "number",
    convertToLocaleString: false,
    rowid: 0
  },
  // {
  //   id: "designClass",
  //   header: "Design Class",
  //   accessorKey: "designClass",
  //   alignData: "left",
  //   editable: false,
  //   type: "text",
  //   convertToLocaleString: false,
  // },
  {
    id: "nominalCapacity",
    header: "Nominal TEU",
    accessorKey: "nominalCapacity",
    alignData: "left",
    editable: true,
    type: "number",
    convertToLocaleString: false,
    rowid: 2
  },
  {
    id: "tcd",
    accessorKey: "tcd",
    header: "TCD ($)",
    editable: true,
    type: "number",
    convertToLocaleString: true,
    alignData: "right",
    dollarSign: true,
    meta: {
      inputProps: function noRefCheck() {},
    },
    rowid: 3
  },
  {
    id: "consupmtionInTons",
    accessorKey: "consupmtionInTons",
    header: "R/T Cons (MT)",
    type: "number",
    alignData: "right",
    editable: false,
    convertToLocaleString: false,
    rowid: 0
  },
  {
    id: "dailyConsumption",
    accessorKey: "dailyConsumption",
    header: "Daily CONS (MT)",
    type: "number",
    alignData: "right",
    editable: false,
    convertToLocaleString: false,
    rowid: 0
  },
  {
    id: "consumptionInUSD",
    accessorKey: "consumptionInUSD",
    header: "R/T Cons ($)",
    type: "number",
    alignData: "right",
    editable: true,
    dollarSign: true,
    convertToLocaleString: true,
    rowid: 4
  },
  {
    id: "tc",
    accessorKey: "tc",
    header: "R/T TC ($)",
    type: "number",
    alignData: "right",
    editable: false,
    convertToLocaleString: true,
    dollarSign: true,
    rowid: 0
  },
  {
    id: "portExpense",
    accessorKey: "portExpense",
    header: "R/T PEX ($)",
    type: "number",
    alignData: "right",
    editable: true,
    convertToLocaleString: true,
    dollarSign: true,
    rowid: 5
  },
  {
    id: "roundTripCost",
    accessorKey: "roundTripCost",
    header: "R/T Cost ($)",
    type: "number",
    alignData: "right",
    editable: false,
    convertToLocaleString: true,
    dollarSign: true,
    rowid: 0
  },
  {
    accessorKey: "oneOffCost",
    header: "One Off ($)",
    id: "oneOffCost",
    type: "number",
    editable: true,
    convertToLocaleString: true,
    alignData: "right",
    dollarSign: true,
    rowid: 6
  },
  {
    accessorKey: "yearlyCost",
    header: "Yearly Cost ($)",
    id: "yearlyCost",
    type: "number",
    editable: false,
    convertToLocaleString: true,
    alignData: "right",
    dollarSign: true,
    rowid: 0
  },
  {
    id: "intakeCalucation",
    header: "Intake Calucation",
    accessorKey: "intakeCalucation",
    alignData: "left",
    editable: false,
    type: "number",
    convertToLocaleString: false,
    isRowSeparator: true,
    rowid: 0
  },
  {
    id: "capturaIntake",
    accessorKey: "capturaIntake",
    header: "Captura Intake",
    editable: true,
    type: "number",
    alignData: "right",
    convertToLocaleString: false,
    rowid: 7
  },
  {
    id: "allocation",
    accessorKey: "allocation",
    header: "Allocation (%)",
    type: "number",
    editable: true,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 8
  },

  {
    id: "headHaul",
    header: "Head Haul",
    accessorKey: "headHaul",
    alignData: "left",
    type: "number",
    editable: false,
    convertToLocaleString: false,
    isRowSeparator: true,
    rowid: 0
  },
  {
    id: "hhCapturaIntake",
    accessorKey: "hhCapturaIntake",
    header: "Captura Intake (TEU)",
    type: "number",
    editable: true,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 7
  },
  {
    id: "hhPartnerAllocationPercentage",
    accessorKey: "hhPartnerAllocationPercentage",
    header: "Partner Allocation (%)",
    type: "number",
    editable: true,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 8
  },
  {
    id: "hhPartnerAllocation",
    accessorKey: "hhPartnerAllocation",
    header: "Partner Allocation (TEU)",
    type: "number",
    editable: true,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 9
  },
  {
    id: "hhmskAllocation",
    accessorKey: "hhmskAllocation",
    header: "MSK allocation (TEU)",
    type: "number",
    editable: false,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 0
  },
  {
    id: "hhDryDemand",
    accessorKey: "hhDryDemand",
    header: "MSK Dry Demand (TEU)",
    type: "number",
    editable: true,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 10
  },
  {
    id: "hhReeferDemand",
    accessorKey: "hhReeferDemand",
    header: "MSK Reefer Demand (FFE)",
    type: "number",
    editable: true,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 11
  },
  {
    id: "hhDryCY",
    accessorKey: "hhDryCY",
    header: "Dry CY (FFE)",
    type: "number",
    editable: true,
    alignData: "right",
    convertToLocaleString: true,
    dollarSign: true,
    rowid: 12
  },
  {
    id: "hhReeferCY",
    accessorKey: "hhReeferCY",
    header: "Reefer CY (FFE)",
    type: "number",
    editable: true,
    alignData: "right",
    convertToLocaleString: true,
    dollarSign: true,
    rowid: 13
  },
  {
    id: "hhUtilization",
    accessorKey: "hhUtilization",
    header: "Utilization",
    type: "number",
    editable: false,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 0
  },
  {
    id: "hhPerToNominal",
    accessorKey: "hhPerToNominal",
    header: "% to Nominal",
    type: "number",
    editable: false,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 0
  },
  {
    id: "backHaul",
    header: "Back Haul",
    accessorKey: "backHaul",
    alignData: "left",
    type: "number",
    editable: false,
    convertToLocaleString: false,
    isRowSeparator: true,
    rowid: 0
  },
  {
    id: "bhCapturaIntake",
    accessorKey: "bhCapturaIntake",
    header: "Captura Intake (TEU)",
    type: "number",
    editable: true,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 14
  },
  {
    id: "bhPartnerAllocationPercentage",
    accessorKey: "bhPartnerAllocationPercentage",
    header: "Partner Allocation (%)",
    type: "number",
    editable: true,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 15
  },
  {
    id: "bhPartnerAllocation",
    accessorKey: "bhPartnerAllocation",
    header: "Partner Allocation (TEU)",
    type: "number",
    editable: true,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 16
  },
  {
    id: "bhmskAllocation",
    accessorKey: "bhmskAllocation",
    header: "MSK allocation (TEU)",
    type: "number",
    editable: false,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 0
  },
  {
    id: "bhDryDemand",
    accessorKey: "bhDryDemand",
    header: "MSK Dry Demand (TEU)",
    type: "number",
    editable: true,
    alignData: "right",
    convertToLocaleString: true,
    rowid: 17
  },
  {
    id: "bhReeferDemand",
    accessorKey: "bhReeferDemand",
    header: "MSK Reefer Demand (FFE)",
    type: "number",
    editable: true,
    alignData: "right",
    convertToLocaleString: true,
    rowid: 18
  },
  {
    id: "bhDryCY",
    accessorKey: "bhDryCY",
    header: "Dry CY (FFE)",
    type: "number",
    editable: true,
    alignData: "right",
    convertToLocaleString: true,
    dollarSign: true,
    rowid: 19
  },
  {
    id: "bhReeferCY",
    accessorKey: "bhReeferCY",
    header: "Reefer CY (FFE)",
    type: "number",
    editable: true,
    alignData: "right",
    convertToLocaleString: true,
    dollarSign: true,
    rowid: 20
  },
  {
    id: "bhUtilization",
    accessorKey: "bhUtilization",
    header: "Utilization",
    type: "number",
    editable: false,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 38
  },
  {
    id: "bhPerToNominal",
    accessorKey: "bhPerToNominal",
    header: "% to Nominal",
    type: "number",
    editable: false,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 39
  },
  {
    id: "ranking",
    header: "Ranking",
    accessorKey: "ranking",
    alignData: "left",
    type: "number",
    editable: false,
    convertToLocaleString: false,
    isRowSeparator: true,
    rowid: 40
  },
  {
    id: "roundTripMSKAllocation",
    accessorKey: "roundTripMSKAllocation",
    header: "R/T MSK allocation (TEU)",
    type: "number",
    editable: false,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 41
  },
  {
    id: "roundTripDemand",
    accessorKey: "roundTripDemand",
    header: "R/T Demand (TEU)",
    type: "number",
    editable: false,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 42
  },
  {
    id: "mskYearAllocation",
    accessorKey: "mskYearAllocation",
    header: "MSK Year Allocation (TEU)",
    type: "number",
    editable: false,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 43
  },
  {
    id: "mskYearDemand",
    accessorKey: "mskYearDemand",
    header: "MSK Year demand (TEU)",
    type: "number",
    editable: false,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 44
  },
  {
    accessorKey: "slotCost",
    header: "Slot Cost ($)",
    id: "slotCost",
    type: "number",
    alignData: "right",
    editable: false,
    convertToLocaleString: true,
    dollarSign: true,
    rowid: 45
  },
  {
    id: "unitCost",
    accessorKey: "unitCost",
    header: "Unit Cost",
    type: "number",
    editable: false,
    alignData: "right",
    convertToLocaleString: false,
    dollarSign: true,
    rowid: 46
  },
  {
    accessorKey: "nopatDay",
    header: "NOPAT ($/Day)",
    id: "nopatDay",
    convertToLocaleString: true,
    type: "number",
    alignData: "right",
    editable: false,
    dollarSign: true,
    rowid: 48
  },
  {
    accessorKey: "nopatRT ",
    header: "NOPAT by R/T",
    id: "nopatRT",
    convertToLocaleString: true,
    type: "number",
    alignData: "right",
    editable: false,
    dollarSign: true,
    rowid: 49
  },
  {
    id: "serviceRanking",
    accessorKey: "serviceRanking",
    header: "Service Ranking",
    type: "number",
    editable: false,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 50
  },
  {
    id: "overallRanking",
    accessorKey: "overallRanking",
    header: "Overall Ranking",
    type: "number",
    editable: true,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 51
  },
  {
    accessorKey: "tcDifference",
    header: "TC Diff ($)",
    id: "tcDifference",
    convertToLocaleString: true,
    type: "number",
    alignData: "right",
    editable: false,
    dollarSign: true,
    rowid: 52
  },
  {
    id: "carbonEmission",
    header: "Carbon Emission",
    accessorKey: "carbonEmission",
    alignData: "left",
    type: "text",
    editable: false,
    convertToLocaleString: false,
    showHeadersSmall: true,
    isRowSeparator: true,
    rowid: 53
  },
  {
    id: "mtCo2",
    accessorKey: "mCarbon",
    header: "mtCo2",
    type: "number",
    editable: false,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 54
  },
  {
    id: "gCo2",
    accessorKey: "gCarbon",
    header: "gCo2",
    type: "number",
    editable: false,
    alignData: "right",
    convertToLocaleString: false,
    rowid: 55
  },
];
