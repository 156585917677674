// React
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Anchor UI
import { toastEmitter } from "@anchor/react-components/dist/lib/components/Toast";
// Components and Contexts
import { SkeletonLoader } from "../../../Components/SkeletonLoader/SkeletonLoader";
import { RefreshTableContext } from "../../../contextProviders/RefreshTableContext";
import ProposalDetails from "../../FBPValidation/AddOnComponent/ProposalDetails";
import NoData from "../../../Components/NoData/NoData";

// Services
import { getAPI } from "../../../Services/APIservices";

//styles
import "../../DtcoOverviewPage/DtcoOverviewPage.scss";
// Models and Constants
import { DtcoModel } from "../../DtcoOverviewPage/DtcoModel";
import DtcoIteration from "../../DtcoOverviewPage/DtcoIteration/DtcoIteration";
import CharteringManagerOverViewTable from "./CharteringManagerOverviewTable";
import { tableColumns } from "./tableColumns";
import DtcoErrorDetailsModal from "../../DtcoOverviewPage/DtcoIteration/DtcoErrorDetailsModal";


const CharteringManagerOverViewPage = () => {
  const { requestId } = useParams<{ requestId: string }>();
  const [dtcoData, setDtcoData] = useState<DtcoModel>({
    dtcoSummaries: [],
    dtcoImpacts: null,
    errorDetails: null,
    allowEditDTCOCalculation: false,
    workflowStatusName: "",
    dtcoRequestData: {
      requestName: "",
      createdBy: "",
      dtcoCategoryName: "",
      dtcoTypeName: "",
    },
  });
  const [isTableLoader, setIsTableLoader] = useState(true);
  const [refreshTable, setRefreshTable] = useState(false);
  const [checkRunRefresh, setCheckRunRefresh] = useState(false);
  const [errorDetails, setErrorDetails] = useState<string[]>([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const getDTCOOverviewData = async () => {
    const url = `dtco-api/dtcosummary/GetDTCOOverviewData/${requestId}`;
    setIsTableLoader(true);
    await getAPI(url)
      .then((response) => {
        setDtcoData(response);
        setIsTableLoader(false);
      })
      .catch((error) => {
        toastEmitter(
          {
            className: "customClassName",
            title: "Error occured while fetching DTCO overview data.",
          },
          { type: "error" }
        );
        console.error("An error occurred:", error);
        setIsTableLoader(false);
      });
  };
  useEffect(() => {
    getDTCOOverviewData();
  }, []);

  useEffect(() => {
    if (refreshTable) {
      getDTCOOverviewData();
      setRefreshTable(false);
    }
  }, [refreshTable]);

  useEffect(() => {
    if (checkRunRefresh) {
      setCheckRunRefresh(false);
      setIsTableLoader(false);
    }
  }, [checkRunRefresh]);

  const handleChangeForDtcoData = (data: DtcoModel) => {
    setIsTableLoader(true);
    setDtcoData(data);
    if (data?.errorDetails !== null && data?.errorDetails?.length > 0) {
      setErrorDetails(data?.errorDetails);
      setOpenErrorModal(true);
    }
    setCheckRunRefresh(true);
  };
  useEffect(() => {
    if (checkRunRefresh) {
      setCheckRunRefresh(false);
      setIsTableLoader(false);
    }
  }, [checkRunRefresh]);

  return (
    <RefreshTableContext.Provider
      value={{ refreshTable, checkRunRefresh, setRefreshTable }}
    >
      <div className="dtco-overview-page p-3">
        <div className="dtco-overview-page-title">
          <h1 className="header-text">Initiate Comparison Proposal</h1>
        </div>
        <div className="proposalDetails">
          <ProposalDetails
            reuqestName={dtcoData?.dtcoRequestData?.requestName}
            createdBy={dtcoData?.dtcoRequestData?.createdBy}
            driversCategory={dtcoData?.dtcoRequestData?.dtcoCategoryName}
            dtcoType={dtcoData?.dtcoRequestData?.dtcoTypeName}
            isCharter={dtcoData?.dtcoRequestData?.requestType === "Chartering"}
          />
        </div>
        {
          dtcoData?.allowEditDTCOCalculation && (
            <DtcoIteration
              requestId={requestId}
              dtcoData={dtcoData}
              handleDtcoData={handleChangeForDtcoData}
              refreshRunPerforma={refreshTable}
              isCharter={dtcoData?.dtcoRequestData?.requestType === "Chartering"}
            />
          )}
        <div>
          {isTableLoader ? (
            <SkeletonLoader isLoader={isTableLoader}></SkeletonLoader>
          ) : dtcoData?.dtcoSummaries?.length > 0 ? (
            <>
              <CharteringManagerOverViewTable
                headers={tableColumns}
                data={dtcoData?.dtcoSummaries}
                requestId={requestId}
                status={dtcoData?.dtcoRequestData?.statusName}
                isAllowEdit={dtcoData?.allowEditDTCOCalculation}
                isOld = {dtcoData?.dtcoRequestData?.isOld}
                isCharter={dtcoData?.dtcoRequestData?.requestType === "Chartering"}
              />
            </>
          ) : (
            <div
              className={` ${dtcoData?.allowEditDTCOCalculation ? "" : "mt-5"}`}
            >
              <NoData />
            </div>
          )}
        </div>
        <div>
          <DtcoErrorDetailsModal
            showErrorModal={openErrorModal}
            setShowErrorModal={setOpenErrorModal}
            errorDetails={errorDetails}
            isCharter={dtcoData?.dtcoRequestData?.requestType === "Chartering"}
          />
        </div>
      </div>
    </RefreshTableContext.Provider>
  );
};
export default CharteringManagerOverViewPage;