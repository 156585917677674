// React
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Anchor Components
import {
  TextArea,
  Avatar,
  Button,
  toastEmitter,
} from "@anchor/react-components/dist/lib/components";

// Constants
import { AppString } from "../../utils/AppString";
import { appRoles } from "../../utils/Constants";

// Services
import { getAPI, postAPI } from "../../Services/APIservices";

// Components
import Table from "./AddOnComponent/Table";
import PermutationTable from "./AddOnComponent/PermutationTable";
import StepIndicator from "../../Components/StepIndicator/StepIndicator";
import PageLoader from "../../Components/PageLoader/PageLoader";
import ProposalDetails from "./AddOnComponent/ProposalDetails";
import ConfirmationModalPopUp from "./AddOnComponent/ConfirmationModalPopUp";
import DisplayDeltaProposalTable from "../DtcoOverviewPage/DisplayDelta/DisplayDeltaProposalTable";
import DisplayDeltaTableDetails from "../DtcoOverviewPage/DisplayDelta/DisplayDeltaTableDetails";

// CSS
import "./FBPValidation.scss";

const FBPvalidation = () => {
  const history = useNavigate();
  const userRole = sessionStorage.getItem("userRole");
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const requestID = params.get("requestId");
  const [mainData, setMainData] = useState<any>([]);
  const [isPageLoader, setIsPageLoader] = useState<boolean>(false);
  const [isSubmitModal, setIsSubmitModal] = useState<boolean>(false);
  const [dtcoComments, setDtcoComments] = useState<string>('');
  const handleSubmitValidation = async () => {
      setIsSubmitModal(true);
  };
  const modalSubmit = async () => {
    const payload = {
      dtcoImpactJson: "string",
      dtcoImpact: mainData?.dtcoImpact,
      comment: dtcoComments === undefined ? "" : dtcoComments?.replaceAll("\r\n", "<br>"),
      isDraftRequest: false,
      maxNopat: 0,
      fbpReviewer: mainData?.fbpReviewer,
      DeploymentHeadReviewer: mainData?.deploymentHeadReviewer,
    };
    const response = await postAPI(
      `dtco-api/dtcosummary/SaveDTCORequestProposal/${requestID}`,
      payload
    );
    if (response === true) {
      toastEmitter(
        {
          className: "customClassName",
          title: AppString.savedSuccessfullyToastComment,
        },
        { type: "success" }
      );
      history(`/RequestPage/DeploymentValidation?requestId=${requestID}`);
    } else {
      toastEmitter(
        { className: "customClassName", title: AppString.errorToastComment },
        { type: "error" }
      );
    }
  };

  // Function to transform all the keys of an object to lowercase since these are stored as raw data 
  const transformKeysToLowercase = (data: any[])  =>{
    return data?.map((item: any) => {
      return Object.keys(item).reduce((acc: any, key: string) => {
        acc[key.charAt(0).toLowerCase() + key.slice(1)] = item[key];
        return acc;
      }, {});
    });
  }

  const getMainData = async () => {
    try {
      setIsPageLoader(true);
      const response = await getAPI(`dtco-api/dtcosummary/GetReviewerDetailForDtcoRequestProposal/${requestID}`);
  
      if (response) {
        const parsedResponse = {
          ...response,
          exceptionalCaseDelta: JSON.parse(response?.exceptionalCaseDelta),
          exceptionalCaseSummary: JSON.parse(response?.exceptionalCaseSummary)
        };
  
        parsedResponse.exceptionalCaseSummary = transformKeysToLowercase(parsedResponse.exceptionalCaseSummary);
        parsedResponse.exceptionalCaseDelta = transformKeysToLowercase(parsedResponse.exceptionalCaseDelta);
  
        setMainData(parsedResponse);
  
        const updatedComment = parsedResponse?.comment?.replaceAll(/<br\s*\\?>/g, "\r\n");
        setDtcoComments(updatedComment);
      }
    } catch (error) {
      console.error('Failed to get proposal data:', error);
    } finally {
      setIsPageLoader(false);
    }
  };

  const handleSaveDraft = async () => {
    const payload = {
      dtcoImpactJson: "string",
      dtcoImpact: mainData?.dtcoImpact,
      comment: dtcoComments === null ? "" : dtcoComments?.replaceAll("\r\n", "<br>"),
      isDraftRequest: true,
      maxNopat: 0,
      fbpReviewer: mainData?.fbpReviewer,
      DeploymentHeadReviewer: mainData?.deploymentHeadReviewer,
    };
    const response = await postAPI(
      `dtco-api/dtcosummary/SaveDTCORequestProposal/${requestID}`,
      payload
    );
    if (response === true) {
      toastEmitter(
        { className: "customClassName", title: "Saved successfully" },
        { type: "success" }
      );
    }
  };
  useEffect(() => {
    if (userRole !== appRoles.DeploymentUser) {
      history("/AccessDenied");
    }
    getMainData();
  }, []);
  return (
    <div className="fbpvalidation-page p-3 ">
      <PageLoader isLoader={isPageLoader} />
      <h1 className="header-text mb-3"> Send to Reviewers for Validation</h1>
      <div className="bck-button">
        <Button
          label="DTCO Proposal"
          appearance="alt"
          fit="small"
          icon="arrow-left"
          variant="filled"
          onClick={() => history(`/RequestPage/DtcoOverviewPage/${requestID}`)}
        ></Button>
      </div>
      <StepIndicator
        requestID={requestID}
        triggerPoint={isPageLoader}
      ></StepIndicator>
      <div className="data-section">
        <ProposalDetails
          reuqestName={mainData?.dtcoRequestData?.requestName}
          createdBy={mainData?.dtcoRequestData?.createdBy}
          driversCategory={mainData?.dtcoRequestData?.dtcoCategoryName}
          dtcoType={mainData?.dtcoRequestData?.dtcoTypeName}
        />
        <div className="tableData-section mb-4">
          <h4 className="sub-header-text mb-3">Proposal from Deployment</h4>
          {mainData?.impactPermutations !== null && (
            <div className="permutation-table mb-3">
              <PermutationTable originalJSON={mainData?.impactPermutations} />
            </div>
          )}
          {mainData?.exceptionalCaseSummary?.length > 0 && (
            <div className="mb-3">
              <DisplayDeltaProposalTable
                displayImpactTableData={mainData?.exceptionalCaseSummary}
                isEbitEdit={false}
              />
            </div>
          )}

          {mainData?.exceptionalCaseSummary?.length > 0 && (
            <div className="exceptionCaseDetailTable">
              <DisplayDeltaTableDetails data={mainData?.exceptionalCaseDelta} />
            </div>
          )}

          {mainData?.dtcoImpact !== null && (
            <Table originalJSON={mainData?.dtcoImpact} />
          )}
        </div>
        <div className="user-section mb-4">
          <h4 className="sub-header-text mb-3">Reviewers for validation</h4>
          <div className="row">
            <div className="col-md-4">
              <div className="reviewer">
                <div className="div-section">
                  <div className="img-section">
                    <Avatar
                      appearance="color1"
                      fit="medium"
                      imagesrc=""
                      info="Tooltip text"
                      initials="FBP"
                    />
                  </div>
                  <div className="name-section">
                    <div className="tittle">Finance Business Partner</div>
                    <div className="name">
                      {mainData?.fbpReviewer?.reviewerName}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="reviewer">
                <div className="div-section">
                  <div className="img-section">
                    <Avatar
                      appearance="color4"
                      fit="medium"
                      imagesrc=""
                      info="Tooltip text"
                      initials="DH"
                    />
                  </div>
                  <div className="name-section">
                    <div className="tittle">Deployment Head</div>
                    <div className="name">
                      {mainData?.deploymentHeadReviewer?.reviewerName}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="comment-section mb-4">
          <h4 className="sub-header-text mb-3">Add a comment</h4>
          <div className="comment-box">
            <TextArea
              fit="medium"
              id="textInput"
              label=""
              value={dtcoComments}
              onChange={(e) => {
                setDtcoComments(e.target.value);
              }}
              rows={3}
              maxLength={4000}
            ></TextArea>
          </div>
        </div>
        <ConfirmationModalPopUp
          modalHeader="Send proposal"
          isOpen={isSubmitModal}
          modalBody="Are you sure you want to send the proposal ?"
          modalButton={{ primary: "Send", secondary: "Cancel" }}
          onClose={() => setIsSubmitModal(false)}
          submitFun={modalSubmit}
        />
        {mainData?.isDraftRequest !== false &&
          userRole === "DeploymentUser" && (
            <div className="button-section">
              <div className="action-div">
                <Button
                  appearance="default"
                  id="primary"
                  justifyItems="center"
                  label="Save Draft"
                  name="saveDraft"
                  fit="small"
                  onClick={() => handleSaveDraft()}
                  variant="outlined"
                />
                <Button
                  appearance="default"
                  id="primary"
                  justifyItems="center"
                  label="Send  for Validation"
                  name="SendValidation"
                  fit="small"
                  onClick={() => handleSubmitValidation()}
                  variant="filled"
                />
              </div>
            </div>
          )}
      </div>
    </div>
  );
};
export default FBPvalidation;
