import { Icon } from "@anchor/react-components/dist/lib/components";
import './SimulationBanner.scss';
import { useState } from "react";
import moment from "moment";
import '../../SimulationRequest.scss'
const SimulationBanner = ({ data }) => {
  const [showMore, setShowMore] = useState(false);
  const [showIdleVessel, setShowIdleVessel] = useState(false);
  const serviceCodes = data?.serviceCodes || "";
  const idleVesselList = data?.idleVessels || "";
  const maxLength = 90;
  const serviceIds = data?.serviceCodes?.split(",");

  return (
    <>
      <div className="data-section simulation-banner">
        <div className="simulationList p-2">
          <div className="icon-section">
            <Icon size="24" name="vessel-front" />
          </div>
          <div className="value-section">
            <div className="data-list">
              <div className="data-subtitle">
                <div className="upper-section">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="header">Vessel:</div>
                      <div className="content">
                        <span>{data?.vesselName}</span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="header">Optimization Type:</div>
                      <div className="content">
                        <span>{data?.optimizationType}</span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="header">
                        Service list ({serviceIds?.length}):{" "}
                      </div>
                      <div className="content">
                        <div>
                          <span>
                            {showMore
                              ? serviceCodes
                              : `${serviceCodes?.substring(0, maxLength)}${serviceCodes?.length > maxLength ? "..." : ""
                              }`}
                          </span>
                          {serviceCodes?.length > maxLength && (
                            <span className="showmore" onClick={() => setShowMore(!showMore)}>
                              {showMore ? "Show Less" : "Show More"}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="header">Idle Vessel:</div>
                      <div className="content">
                        <div>
                          <span>
                            {showIdleVessel
                              ? idleVesselList
                              : `${idleVesselList?.substring(0, maxLength)}${idleVesselList?.length > maxLength ? "..." : ""
                              }`}
                          </span>
                          {idleVesselList?.length > maxLength && (
                            <span className="showmore" onClick={() => setShowIdleVessel(!showIdleVessel)}>
                              {showIdleVessel ? "Show Less" : "Show More"}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      {
                        data?.runId && (
                          <div className="col-md-3">
                            <div className="header">
                              <span>
                                Run ID :<span>{data?.runId}</span>
                              </span>
                            </div>
                          </div>
                        )}
                      <div className="col-md-3">
                        <div className="header">
                          <span>
                            Created By :<span>{data?.createdBy}</span>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="header">
                          <span>
                            Request Name :<span>{data?.requestName}</span>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="header">
                          <span>
                            Created On :
                            <span>
                              {moment(data?.createdOn).format("DD/MM/YYYY")}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SimulationBanner;
