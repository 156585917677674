//React
import React from 'react';
import { useNavigate } from 'react-router-dom';
//Components
import { Icon } from '@anchor/react-components/dist/lib/components';
//Styles
import './Dictionary.scss';

export const Dictionary = () => {
    const navigate = useNavigate();
    const dictionaryListData = [
        {
            "name": "List of Special Vessels",
            "firstLetter": "L",
            "description": "Display the vessels that are not included in the simulation model",
            "action": "/Dictionary/SpecialVessels"
        },

    ]
    return (

        <div className="dictionary-list mt-3 p-4">
            <h1 className='header-text'>Dictionary List </h1>
            <div className="dictionary-list-body mt-3">
                {dictionaryListData.map((item, index) => {
                    return (
                        <div key={index} className='dictionary-list-item' onClick={() => navigate(item.action)} data-testid="list-item" >
                            <div className="d-flex justify-content-between align-items-center px-3 py-2">
                                <div className="d-flex align-items-center">
                                    <div className='dictionary-list-item-icon px-3 py-2 me-2'>{item.firstLetter}</div>
                                    <div>{item.name}</div>
                                </div>

                                <div>{item.description}</div>
                                <div className="d-flex align-items-center ms-2">
                                    <Icon name="chevron-right" size='24' data-testid="next-icon"/>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};
export default Dictionary;