import React, { useEffect, useState } from "react";
import {
  Button,
  ReactSelect,
} from "@anchor/react-components/dist/lib/components";
import { appRoles } from "../../../utils/Constants";
import { listModel } from "../Model";
import { getAPI } from "../../../Services/APIservices";
import { useForm, Controller } from "react-hook-form";
interface GeneratorProps {
  handleGeneratedData: (data: any) => void;
  optimizationType: listModel[];
  idleVessels: listModel[];
}
const GenerateSimulation = ({
  handleGeneratedData, optimizationType,idleVessels
}: GeneratorProps) => {
  const userRole = sessionStorage.getItem("userRole");
  const [vesselList, setVesselList] = useState<listModel[]>([]);
  const [serviceList, setServiceList] = useState<listModel[]>([]);
  const [isSelectLoader, setIsSelectLoader] = useState(false);
  const [selectedOptimizationType, setSelectedOptimizationType] = useState<any>();
  const [selectedVessel, setSelectedVessel] = useState<any>();
  const [selectedService, setSelectedService] = useState<any>();
  const [defaultService, setDefaultService] = useState<any>();
  const [disableService, setDisableService] = useState(true);
  const [disableVessel, setDisableVessel] = useState(true);
  const [loaderForOptimizationType, setLoaderForOptimizationType] = useState(false);
  const [loaderForService, setLoaderForService] = useState(false);
  const { handleSubmit, control, setValue, reset } = useForm({
    defaultValues: {
      optimizationType: [],
      vesselList: [],
      serviceList: [],
      idleVesselList: []
    },
  });
  const getVesseleList = async (optimizationTypeName: any) => {
    setIsSelectLoader(true);
    const response = await getAPI(`simulation-api/simulation/GetVesselDetailsForSimulation/${optimizationTypeName?.label}`);
    if (response) {
      const res = response?.map((item) => {
        return {
          label: item.label + " - " + item.value,
          value: item.value,
        };
      });
      setVesselList(res);
    }
    setIsSelectLoader(false);
  };
  const getServiceList = async (optimizationType: any, defaultValue?: any) => {
    setLoaderForService(true);
    if (optimizationType?.label === "Vessel-Out") {
      setValue('serviceList', defaultValue);
    }
    const response = await getAPI(`dtco-api/dtcosummary/GetActiveServicesAsync/${optimizationType?.label}`);
    if (response) {
      const res = response?.map((item) => {
        return {
          label: item.label,
          value: item.value,
          isDisabled: defaultValue?.some((selected) =>
            selected.value === item.value
          ),
        };
      });
      setServiceList(res);
    }
    setLoaderForService(false);
  };
  const getActiveSelectedService = async (imoNumber: any) => {
    const response = await getAPI(`simulation-api/simulation/GetCurrentServiceForVessel/${imoNumber}`);
    if (response) {
      const res = response?.map((item) => {
        return {
          label: item.label,
          value: item.value,
        };
      });
      
      setDefaultService(res);
      getServiceList(selectedOptimizationType, res);
      setSelectedService(res);
    }
  };

  const onSubmit = (data: any) => {
    const { vesselList, serviceList = [], requestName, idleVesselList } = data;
    const idleVessel = idleVesselList?.map((item) => item.value);
    const serviceID = serviceList?.map((item) => item.value);
    const payload = {
      serviceIds: serviceID,
      imos: vesselList?.value ? [vesselList?.value] : [],
      requestName: requestName ?? "",
      optimizationId: selectedOptimizationType?.value,
      idleVessels: idleVessel?.length > 0 ? idleVessel: null
    };
    handleGeneratedData(payload);
    reset();
    setDisableService(true);
    setDisableVessel(true);
    setSelectedOptimizationType(null);
    setSelectedService(null);
    setSelectedVessel(null);
  };
  useEffect(() => {
    if (selectedVessel !== null && selectedVessel !== undefined && selectedOptimizationType?.label === "Vessel-Out") {
      getActiveSelectedService(selectedVessel?.value);

      setDisableService(false);
    } else if (selectedOptimizationType?.label === "Vessel-In") {
      setDisableService(false);
    } else {
      setDisableService(true);
      setSelectedVessel(null);
      setValue('serviceList', []);
    }
  }, [selectedVessel]);
  useEffect(() => {
    if (selectedOptimizationType == null || selectedOptimizationType === undefined) {
      setDisableService(true);
      setDisableVessel(true);
      reset();
      setSelectedVessel(null);
    } else {
      getVesseleList(selectedOptimizationType);
      if (selectedOptimizationType?.label === "Vessel-Out") {
        setDisableVessel(false);
        setDisableService(true);
      } else if (selectedOptimizationType?.label === "Vessel-In") {
        getServiceList(selectedOptimizationType);
        setDisableVessel(false);
        setDisableService(false);
      }
    }
  }, [selectedOptimizationType]);

  const handleSelectChange = (newValue) => {
    if (selectedOptimizationType?.label === "Vessel-Out") {
      if (newValue.length == 0) {
        setValue("serviceList", defaultService);
        setSelectedService(defaultService);
      } else {
        const newSelectdedValue: any = [...newValue];
        setSelectedService(newSelectdedValue);
        setValue("serviceList", newSelectdedValue);
      }
    } else {
      setSelectedService(newValue);
    }

  }
  return (
    <form data-testid="generate-data" onSubmit={handleSubmit(onSubmit)}>
      <div className="simulation-generation mt-3">
        <h6>Request simulation</h6>
        <div className="row">
          <div className="col-md-11">
            <div className="row">
              <div className="col-md-3">
                <Controller
                  name="optimizationType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      className="multi-select"
                      closeMenuOnSelect
                      customNoOptionsMessage={<p>Not a valid option</p>}
                      isLoading={loaderForOptimizationType}
                      fit="medium"
                      id="selectOptimizationType"
                      isClearable
                      isSearchable
                      onChange={(e) => {
                        onChange(e);
                        setSelectedOptimizationType(e);
                      }}
                      label="Optimization Type"
                      name="optimizationType"
                      options={optimizationType}
                      orientation="vertical"
                      placeholder="Select by Optimization Type"
                      suggestType="static"
                      value={value}
                      width={100}
                      aria-label="Optimization Type"
                      required
                    />
                  )}
                />
              </div>
              <div className="col-md-3">
                <Controller
                  name="vesselList"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      className="multi-select"
                      closeMenuOnSelect
                      customNoOptionsMessage={<p>Not a valid option</p>}
                      isLoading={isSelectLoader}
                      fit="medium"
                      id="selectVessel"
                      isClearable
                      isSearchable
                      onChange={(e) => {
                        onChange(e);
                        setSelectedVessel(e);
                      }}
                      required={selectedOptimizationType?.label === "Vessel-Out"}
                      label="Vessel Name"
                      name="vesselList"
                      options={vesselList}
                      orientation="vertical"
                      placeholder="Select by vessel"
                      suggestType="static"
                      value={value}
                      width={100}
                      aria-label="Vessel"
                      disabled={disableVessel}
                    />
                  )}
                />
              </div>
              <div className="col-md-3">
                <Controller
                  name="serviceList"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      className={`react-select ${selectedOptimizationType?.label}`}
                      closeMenuOnSelect
                      errorMessage="This is error message"
                      fit="medium"
                      id="selectservice"
                      isClearable
                      isSearchable
                      onChange={(e) => {
                        onChange(e);
                        handleSelectChange(e);
                      }}
                      label="Service Names(s)"
                      name="serviceList"
                      options={serviceList}
                      orientation="vertical"
                      placeholder="Select Service from the list"
                      required
                      isMulti
                      value={value}
                      variant="default"
                      width={100}
                      disabled={disableService}
                      isLoading={loaderForService}
                    />
                  )}
                />
              </div>
              <div className="col-md-3">
                <Controller
                  name="idleVesselList"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      className="multi-select"
                      closeMenuOnSelect
                      customNoOptionsMessage={<p>Not a valid option</p>}
                      fit="medium"
                      id="selectIdleVessel"
                      isClearable
                      isSearchable
                      onChange={(e) => {
                        onChange(e);
                      }}
                      required={selectedOptimizationType?.label === "Vessel-Out"}
                      label="Idle Vessel"
                      name="idleVessel"
                      options={idleVessels}
                      orientation="vertical"
                      placeholder="Select idle vessel"
                      suggestType="static"
                      value={value}
                      width={100}
                      aria-label="IdleVessel"
                      isMulti
                    />
                  )}
                />
              </div>
            </div>
          </div>
          {userRole === appRoles.DeploymentUser && (
            <div className="col-md-1">
              <Button
                appearance="default"
                id="btnGenerateReq"
                justifyItems="center"
                label="Request"
                name="primary"
                fit="medium"
                title="Proceed"
                width={100}
                type="submit"
                variant="filled"
              />
            </div>
          )}
        </div>
      </div>
    </form>
  );
};
export default GenerateSimulation;
