import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Input,
  ReactSelect,
} from "@anchor/react-components/dist/lib/components";
import { RunModel, listModel } from "../../Model";
import { getAPI, postAPI } from "../../../../Services/APIservices";
import { toastEmitter } from "@anchor/react-components";
interface IPROPS {
  isModal: boolean;
  onClose: () => void;
  simulationData: RunModel;
  reRender: () => void;
  optimizationType: listModel[];
}
const SimulationDuplicate = ({
  isModal,
  onClose,
  simulationData,
  reRender,
  optimizationType,
}: IPROPS) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [selectedService, setSelectedService] = useState<listModel[]>();
  const [serviceIdsList, setServiceIdsList] = useState<any>();
  const [serviceLists, setServiceLists] = useState<listModel[]>([]);
  const [defaultServiceValue, setDefaultServiceValue] = useState<any>();
  const getServiceList = (res, defaultService) => {
    const serviceIds = simulationData?.serviceIds?.split(",");
    const listedData: listModel[] = res.filter((item: any) => {
      return serviceIds.includes(item.value.toString());
    });
    const filterData = listedData?.map((item) => {
      return {
        label: item.label,
        value: item.value,
        isDisabled: defaultService?.some((selected) =>
          selected.value === item.value
        ),
      };
    });
    
    filterData.sort(function(a,b){return b.isDisabled-a.isDisabled});
    setSelectedService(filterData);
    setServiceIdsList(listedData?.map((item) => item.value))
  };
  const handleRequest = async () => {
    setIsBtnDisabled(true);
    const optimizationTypeId= optimizationType.find((item)=>item.label===simulationData?.optimizationType)?.value;
    
    const payload = {
      serviceIds: serviceIdsList,
      imos: [Number(simulationData?.imos) ?? ""],
      requestName: "",
      OptimizationId: optimizationTypeId,
    };
    const response = await postAPI(
      "simulation-api/simulation/SaveSimulationRequestAsync",
      payload
    );
    if (response === true) {
      toastEmitter(
        {
          className: "customClassName",
          title: "Request submitted successfully",
        },
        { type: "success" }
      );
      reRender();
      setIsBtnDisabled(false);
    } else {
      toastEmitter(
        {
          className: "customClassName",
          title: "Something went wrong",
        },
        { type: "error" }
      );
      setIsBtnDisabled(false);
    }
    oncloseModal();
  };
  const handleServiceList = (e: any) => {
    if (simulationData?.optimizationType === "Vessel-Out") {
      if (e.length == 0) {
        setSelectedService(defaultServiceValue);
      } else {
        const newSelectdedValue: any = [...e];
        setSelectedService(newSelectdedValue);
      }
    } else {
      setSelectedService(e);
    }
    const serviceID = e?.map((item) => item.value);
    setServiceIdsList(serviceID);
  };
  const getActiveSelectedService = async (imoNumber: any) => {
    const response = await getAPI(`simulation-api/simulation/GetCurrentServiceForVessel/${imoNumber}`);
    if (response) {
      const res = response?.map((item) => {
        return {
          label: item.label,
          value: item.value,
        };
      });
      setDefaultServiceValue(res);
      getServiceListApI(res);
    }
  };
  const getServiceListApI = async (defaultService?: any) => {
    const response = await getAPI(`dtco-api/dtcosummary/GetActiveServicesAsync/${simulationData?.optimizationType}`);
    if (response) {
      const res = response?.map((item) => {
        return {
          label: item.label,
          value: item.value,
        };
      });
      getServiceList(res, defaultService);
      setServiceLists(res);
    }
  };
  useEffect(() => {
    if (isModal) {
      setIsShowModal(isModal);
      if (simulationData?.optimizationType === "Vessel-Out") {
        getActiveSelectedService(simulationData?.imos);
      } else {
        getServiceListApI();
      }

    }
  }, [isModal]);

  const oncloseModal = () => {
    setIsShowModal(false);
    onClose();
  };
  return (
    <Modal
      actions={{
        primaryAction: (
          <Button label="Request" disabled={isBtnDisabled} onClick={handleRequest} fit="small" />
        ),
        secondaryAction: (
          <Button
            label="Cancel"
            fit="small"
            variant="outlined"
            onClick={oncloseModal}
          />
        ),
      }}
      heading="Do you want to change the list of services before running the request ?"
      showCloseIcon
      onClose={oncloseModal}
      className="DuplicateModal"
      width="560px"
      height="500px"
      open={isShowModal}
      backdropcloseactiondisabled
    >
      <div className="DuplicateModal">
        <div>
          <Input
            id="duplicateVessel"
            value={simulationData?.vesselName}
            disabled
          />
        </div>
        <div className="ServiceList">
          <ReactSelect
            className={`react-select ${simulationData?.optimizationType}`}
            closeMenuOnSelect
            errorMessage="This is error message"
            fit="medium"
            id="selectservice"
            isClearable
            isSearchable
            onChange={(e) => {
              handleServiceList(e);
            }}
            label="Service Names(s)"
            name="serviceList"
            options={serviceLists}
            orientation="vertical"
            placeholder="Select a Service from the list"
            required
            isMulti
            value={selectedService}
            variant="default"
            width={100}
          />
        </div>
      </div>
    </Modal>
  );
};
export default SimulationDuplicate;
