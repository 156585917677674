// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simulation-result-table tr > th:first-child,
.simulation-result-table tr > td:first-child {
  width: 28px !important;
}
.simulation-result-table tr > th:nth-child(2),
.simulation-result-table tr > td:nth-child(2) {
  width: 30px !important;
}

.solutionRank {
  width: 50%;
  text-align: center;
}

.complete-width {
  width: 100%;
  transition: width 0.3s;
}

.reduced-width {
  width: 72%;
  transition: width 0.3s;
}

.positive {
  color: var(--color-green);
}

.negative {
  color: var(--color-red);
}

.zero {
  color: var(--color-black);
}

.notification {
  background-color: var(--color-white);
}

.bbPYkx {
  min-height: 50px !important;
}

.cuwWAy {
  margin: 10px 16px 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/SimulationRequest/Components/SimulationRequestResult/SimulationRequestResult.scss"],"names":[],"mappings":"AAQI;;EACI,sBAAA;AANR;AASI;;EACI,sBAAA;AANR;;AAUA;EAhBI,UAAA;EACA,kBAAA;AAUJ;;AASA;EACI,WAAA;EACA,sBAAA;AANJ;;AASA;EACI,UAAA;EACA,sBAAA;AANJ;;AASA;EACI,yBAAA;AANJ;;AASA;EACI,uBAAA;AANJ;;AASA;EACI,yBAAA;AANJ;;AASA;EACI,oCAAA;AANJ;;AAUA;EACI,2BAAA;AAPJ;;AAUA;EACI,8BAAA;AAPJ","sourcesContent":["@mixin center-content {\n    width: 50%;\n    text-align: center;\n}\n\n\n.simulation-result-table tr>th,\n.simulation-result-table tr>td {\n    &:first-child {\n        width: 28px !important;\n    }\n\n    &:nth-child(2) {\n        width: 30px !important;\n    }\n}\n\n.solutionRank {\n    @include center-content;\n}\n\n.complete-width {\n    width: 100%;\n    transition: width 0.3s;\n}\n\n.reduced-width {\n    width: 72%;\n    transition: width 0.3s;\n}\n\n.positive {\n    color: var(--color-green);\n}\n\n.negative {\n    color: var(--color-red);\n}\n\n.zero {\n    color: var(--color-black);\n}\n\n.notification {\n    background-color: var(--color-white);\n}\n\n\n.bbPYkx {\n    min-height: 50px !important;\n}\n\n.cuwWAy {\n    margin: 10px 16px 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
