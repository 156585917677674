// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dictionary-list .dictionary-list-body .dictionary-list-item {
  border: solid 1px var(--border-color);
  background: var(--color-white);
  border-radius: 0.25rem;
  cursor: pointer;
}
.dictionary-list .dictionary-list-body .dictionary-list-item .dictionary-list-item-icon {
  background-color: var(--color-maersk-blue);
}

.dictionary-page .error-message {
  color: var(--color-red);
  font-size: var(--font-size-md);
  font-weight: 600;
}
.dictionary-page .mandatory {
  color: var(--color-red);
}
.dictionary-page .add-button-dictionary {
  margin-top: 28px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dictionary/Dictionary.scss"],"names":[],"mappings":"AAEQ;EACI,qCAAA;EACA,8BAAA;EACA,sBAAA;EACA,eAAA;AADZ;AAEY;EACI,0CAAA;AAAhB;;AAOI;EACI,uBAAA;EACA,8BAAA;EACA,gBAAA;AAJR;AAMI;EACI,uBAAA;AAJR;AAMI;EACI,gBAAA;AAJR","sourcesContent":[".dictionary-list {\n    .dictionary-list-body {\n        .dictionary-list-item {\n            border: solid 1px var(--border-color);\n            background: var(--color-white);\n            border-radius: 0.25rem;\n            cursor: pointer;\n            .dictionary-list-item-icon {\n                background-color: var(--color-maersk-blue);\n            }\n        }\n    }\n}\n\n.dictionary-page {\n    .error-message {\n        color: var(--color-red);\n        font-size: var(--font-size-md);\n        font-weight: 600;\n    }\n    .mandatory {\n        color: var(--color-red);\n    }\n    .add-button-dictionary{\n        margin-top: 28px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
