import React from "react";
// React
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Anchor Components
import {
  TextArea,
  Button,
  toastEmitter,
} from "@anchor/react-components/dist/lib/components";
import ProposalDetails from "../../FBPValidation/AddOnComponent/ProposalDetails";
import PageLoader from "../../../Components/PageLoader/PageLoader";
import { getAPI, postAPI } from "../../../Services/APIservices";
import { appRoles } from "../../../utils/Constants";
import { AppString } from "../../../utils/AppString";
import CharteringManagerValidationTable from "./CharteringManagerValidationTable";
import ConfirmationModalPopUp from "../../FBPValidation/AddOnComponent/ConfirmationModalPopUp";

const CharteringManagerValidationScreen = () => {
  const history = useNavigate();
  const { requestId } = useParams<{ requestId: string }>();
  const userRole = sessionStorage.getItem("userRole");
  const [mainData, setMainData] = useState<any>();
  const [isPageLoader, setIsPageLoader] = useState<boolean>(false);
  const [isSubmitModal, setIsSubmitModal] = useState<boolean>(false);
  const [dtcoComments, setDtcoComments] = useState<string>('');
  const checkUserRole = userRole === appRoles.DeploymentUser || userRole === appRoles.CharteringManager;
  const handleSubmitValidation = async () => {
    setIsSubmitModal(true);
  };
  const modalSubmit = async () => {
    const payload = {
      allowEditDTCOCalculation: false,
      dtcoRequestData: mainData.dtcoRequestData,
      dtcoSummaryDetail: mainData.dtcoSummaryDetail,
      notes: dtcoComments,
    };
    const response = await postAPI(
      `dtco-api/dtcosummary/SaveCharteringRequestProposal/${requestId}`,
      payload
    );
    if (response === true) {
      toastEmitter(
        { className: "customClassName", title: AppString.charteringManagerSaveProposalComment },
        { type: "success" }
      );
      getMainData();
    } else {
      toastEmitter(
        { className: "customClassName", title: AppString.errorToastComment },
        { type: "error" }
      );
    }
  };

  const getMainData = async () => {
    try {
      setIsPageLoader(true);
      const response = await getAPI(`dtco-api/dtcosummary/GetCharteringRequestProposalDetailsAsync/${requestId}`);
      if (response) {
        setMainData(response);
        setDtcoComments(response?.notes);
      }
    } catch (error) {
      console.error('Failed to get proposal data:', error);
    } finally {
      setIsPageLoader(false);
    }
  };


  useEffect(() => {
    getMainData();
  }, []);
  return (
    <div className="fbpvalidation-page p-3 ">
      {isPageLoader ? <PageLoader isLoader={isPageLoader} /> : (
        <div>
          <h1 className="header-text mb-3"> Save Comparison Proposal</h1>
          <div className="bck-button">
            <Button
              label="Charter Proposal"
              appearance="alt"
              fit="small"
              icon="arrow-left"
              variant="filled"
              onClick={() => history(`/CharterRequestPage/CharterOverviewPage/${requestId}`)}
            ></Button>
          </div>

          <div className="data-section">
            <ProposalDetails
              reuqestName={mainData?.dtcoRequestData?.requestName}
              createdBy={mainData?.dtcoRequestData?.createdBy}
              driversCategory={mainData?.dtcoRequestData?.dtcoCategoryName}
              dtcoType={mainData?.dtcoRequestData?.dtcoTypeName}
            />
            {
              mainData?.dtcoRequestData?.requestType === "Chartering" && (
                <div className="tableData-section mb-4">
                  <h4 className="sub-header-text mb-3">Winning Result</h4>
                  <div className="mb-3">
                    <CharteringManagerValidationTable tableData={mainData?.dtcoSummaryDetail} />
                  </div>
                </div>
              )
            }
            <div className="comment-section mb-4">
              <h4 className="sub-header-text mb-3">Notes</h4>
              <div className="comment-box">
                <TextArea
                  fit="medium"
                  id="textInput"
                  label=""
                  value={dtcoComments}
                  onChange={(e) => {
                    setDtcoComments(e.target.value);
                  }}
                  rows={3}
                  maxLength={4000}
                  disabled={!checkUserRole || !mainData?.allowEditDTCOCalculation}
                ></TextArea>
              </div>
            </div>
            <ConfirmationModalPopUp
              modalHeader="Save proposal"
              isOpen={isSubmitModal}
              modalBody="Are you sure you want to save the proposal ?"
              modalButton={{ primary: "Save", secondary: "Cancel" }}
              onClose={() => setIsSubmitModal(false)}
              submitFun={modalSubmit}
            />
            {mainData?.allowEditDTCOCalculation === true &&
              (userRole === appRoles.DeploymentUser || userRole == appRoles.CharteringManager) && (
                <div className="button-section">
                  <div className="action-div">
                    <Button
                      appearance="default"
                      id="primary"
                      justifyItems="center"
                      label="Save"
                      name="SaveValidation"
                      fit="small"
                      onClick={() => handleSubmitValidation()}
                      variant="filled"
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
};
export default CharteringManagerValidationScreen;
