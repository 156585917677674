// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simulation-banner {
  border: none !important;
  padding: 0px !important;
  padding-bottom: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/SimulationRequest/Components/SimulationBanner/SimulationBanner.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,uBAAA;EACA,+BAAA;AACJ","sourcesContent":[".simulation-banner{\n    border: none !important;\n    padding: 0px !important;\n    padding-bottom: 10px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
