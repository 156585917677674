import React, { useEffect, useState } from "react";
import "./SimulationRequest.scss";
import {
  Button,
  MultiSelect,
  InputDate,
  ThemeProvider,
  PaginationV2,
} from "@anchor/react-components/dist/lib/components";
import { appRoles } from '../../utils/Constants';
import GenerateSimulation from "./Components/GenerateSimulation";
import SimulationResult from "./Components/SimulationResult";
import NoData from "../../Components/NoData/NoData";
import Loader from "../../Components/GearLoader/Loader";
import { toastEmitter } from "@anchor/react-components";
import { getAPI, postAPI } from "../../Services/APIservices";
import {
  RunModel,
  filterModel,
  Filters,
  listModel
} from "./Model";

const SimulationRequest = () => {
  const userRole = sessionStorage.getItem("userRole");
  const [simulationData, setSimulationData] = useState<RunModel[]>([]);
  const [mainData, setMainData] = useState<RunModel[]>([]);
  const [serviceList, setServiceList] = useState<listModel[]>([]);
  const [filterResponse, setFilterResponse] = useState<filterModel>();
  const [filteredData, setFilteredData] = useState<RunModel[]>([]);
  const datevalue = new Date();

  const [filters, setFilters] = useState<Filters>({
    createdBy: [],
    runDate: { startDate: null, endDate: null},
    status: [],
  });
  const [isLoad, setIsLoad] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const handleGeneratData = async (payload: any) => {
    setIsLoad(true);
    const response = await postAPI(
      "simulation-api/simulation/SaveSimulationRequestAsync",
      payload
    );
    if (response === true) {
      getSimulationData(true);
    } else {
      setIsLoad(false);
      toastEmitter(
        {
          className: "customClassName",
          title: "Something went wrong",
        },
        { type: "error" }
      );
    }
  };

  const getSimulationData = async (isToast?: boolean) => {
    setIsLoad(true);
    const response = await getAPI(
      "simulation-api/simulation/GetAllSimulationRequestDetailAsync"
    );
    setSimulationData(response);
    setFilteredData(response);
    setMainData(response);
    setIsLoad(false);
    resetFilter()
    if (isToast === true) {
      toastEmitter(
        {
          className: "customClassName",
          title: "Created Successfully",
        },
        { type: "success" }
      );
    }
  };

  const RefreshSimulation = async () => {
    setIsLoad(true);
    const response = await getAPI(
      "simulation-api/simulation/CheckRunStatusAsync"
    );
    if (response === true) {
      getSimulationData(false);
    }
  };
  const getFilterResponse = async () => {
    const response = await getAPI(
      "simulation-api/simulation/GetSimulationRequestFiltersAsync"
    );
    setFilterResponse(response);
  };
  const applyFilter = (value, filterType) => {
    console.log(value, filterType);
    setFilters((prevFilters) => {
      switch (filterType) {
        case "createdby":
          return { ...prevFilters, createdBy: value };
        case "runDate":
          return { ...prevFilters, runDate: value };
        case "status":
          return { ...prevFilters, status: value };
        default:
          return prevFilters;
      }
    });
  };
  const resetFilter = () => {
    setFilters({
      createdBy: [],
      runDate: { startDate: datevalue, endDate: datevalue},
      status: [],
    });
  };
  const filterData = () => {
    let data = mainData;

    if (filters.createdBy.length > 0) {
      data = data.filter((item) =>
        filters.createdBy.some((filter) => filter.label === item.createdBy)
      );
    }
    if (filters.runDate[0] && filters.runDate[1]) {
      data = data.filter(item => {
        const itemDate = new Date(item.createdOn);
        const startDate = filters.runDate[0];
        const endDate = filters.runDate[1];
      
        if (startDate && endDate) {
          if (startDate.getTime() === endDate.getTime()) {
            return itemDate.toDateString() === startDate.toDateString();
          } else {
            return itemDate >= startDate && itemDate <= endDate;
          }
        }
        return false;
      });
    }
    if (filters.status.length > 0) {
      data = data.filter((item) =>
        filters.status.some((filter) => filter.label === item.runCurrentStatus)
      );
    }

    setSimulationData(data);
    setCurrentPage(1);
  };
  const getServiceList = async () => {
    const response = await getAPI(`dtco-api/dtcosummary/GetActiveServicesAsync`);
    if (response) {
      const res = response?.map((item) => {
        return {
          label: item.label,
          value: item.value,
        };
      });
      setServiceList(res);
    }
  };
  useEffect(() => {
    filterData();
  }, [filters]);
  useEffect(() => {
    getSimulationData(false);
    getFilterResponse();
    getServiceList()
  }, []);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size: number) => {
    setItemsPerPage(size);
    setCurrentPage(1);
  };

  const paginatedData = simulationData?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="simulation-section p-3">
      <h1 className="header-text">Simulation Model</h1>
      <GenerateSimulation serviceList={serviceList} handleGeneratedData={handleGeneratData} />

      <section className="data-section">
        <div className="result-header">
          <span>Simulations</span>
          {userRole === appRoles.DeploymentUser && (
              <div className="RefreshBtn">
              <Button
                appearance="default"
                id="primary"
                icon="arrow-clockwise"
                justifyItems="center"
                label="Refresh Status"
                name="primary"
                fit="small"
                onClick={RefreshSimulation}
                title="Refresh"
                variant="outlined"
              />
            </div>
          )}
          <div className="filter-div mt-2">
            <div className="row">
              <div className="col-md-4">
                <MultiSelect
                  className="react-select"
                  closeMenuOnSelect
                  fit="small"
                  id="selectservice"
                  isClearable
                  isSearchable
                  onChange={(e) => {
                    applyFilter(e, "createdby");
                  }}
                  label="Created By"
                  name="serviceList"
                  options={filterResponse?.requestCreatedUsers ?? []}
                  orientation="vertical"
                  placeholder="Select a Service from the list"
                  hasSelectAll={false}
                  value={filters.createdBy}
                  variant="default"
                  width={100}
                />
              </div>
              <div className="col-md-4">
                <ThemeProvider>
                  <InputDate
                    dateFormat="dd/MM/yyyy"
                    fit="small"
                    id="dateInput"
                    label="Run Date"
                    onChange={(e) => applyFilter(e, "runDate")}
                    onRangeChange={(e) => applyFilter(e, "runDate")}
                    selectsRange
                    startDate={filters.runDate.startDate}
                    endDate={filters.runDate.endDate}
                  ></InputDate>
                </ThemeProvider>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-10">
                    <MultiSelect
                      className="react-select text-capitalize"
                      closeMenuOnSelect
                      fit="small"
                      id="selectservice"
                      isClearable
                      isSearchable
                      onChange={(e) => {
                        applyFilter(e, "status");
                      }}
                      label="Status"
                      name="serviceList"
                      options={filterResponse?.runStatuses ?? []}
                      orientation="vertical"
                      placeholder="Select a Service from the list"
                      hasSelectAll={false}
                      value={filters.status}
                      variant="default"
                      width={100}
                    />
                  </div>
                  <div className="col-md-2">
                    <Button
                      appearance="default"
                      className="reset-btn mt-4"
                      fit="small"
                      icon="arrow-clockwise"
                      id="primary"
                      justifyItems="center"
                      label=""
                      name="primary"
                      onClick={resetFilter}
                      title="Reset"
                      variant="outlined"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoad ? (
          <Loader />
        ) : (
          <div className="data-div">
            {paginatedData?.length !== 0 ? (
              <>
                {paginatedData?.map((item) => (
                  <SimulationResult
                    key={item.requestId}
                    data={item}
                    reRenderData={() => getSimulationData(false)}
                    serviceListType={serviceList}
                  />
                ))}
                <PaginationV2
                  ariaLabel="Navigate pages"
                  currentPage={currentPage}
                  defaultSelectedValue={itemsPerPage}
                  fit="small"
                  className="mt-3 mb-3"
                  hidePageSizeOptions={false}
                  nextLabel="Next"
                  onPageChange={handlePageChange}
                  onPageSizeOptionsChange={handlePageSizeChange}
                  pageSizeOptions={[5, 10, 20, 30, 50]}
                  previousLabel="Previous"
                  totalPages={Math.ceil(simulationData?.length / itemsPerPage)}
                  visiblePages={5}
                />
              </>
            ) : (
              <NoData />
            )}
          </div>
        )}
      </section>
    </div>
  );
};

export default SimulationRequest;
