import React from 'react';
interface IPROPS {
    reuqestName?: string;
    dtcoType?: string;
    driversCategory?: string;
    createdBy?: string;
    isCharter?: boolean;
}
const ProposalDetails = ({reuqestName, dtcoType, driversCategory, createdBy, isCharter}:IPROPS) => {
    return (<div className="details-section mb-3">
    {/* <h4 className="sub-header-text mb-3">Proposal Details</h4> */}
    <div className="details-box p-2">
      <div className="row">
        <div className="col-md-3">
          <div className="label-text">Request Name</div>
          <div className="value-text">{ reuqestName }</div>
        </div>
        <div className="col-md-3">
          <div className="label-text">{ isCharter ? "TCO Type": "DTCO Type"}</div>
          <div className="value-text">{ dtcoType }</div>
        </div>
        <div className="col-md-3">
          <div className="label-text">Drivers Category</div>
          <div className="value-text">{ driversCategory }</div>
        </div>
        <div className="col-md-3">
          <div className="label-text">Created By</div>
          <div className="value-text">{ createdBy }</div>
        </div>
      </div>
    </div>
  </div>)
}
export default ProposalDetails;